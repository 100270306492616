
(function($) {

  /**
    * Homepage bricks behavior
    *
    */
  Drupal.behaviors.home_formatter_mobile_v1 = {
    attach: function(context, settings) {

      // Landing page body class because template field does not allow us to preprocess page
      if ($('.homepage-bricks').length > 0) {
        $('body').addClass(Drupal.ELB.ui.landing_class); // this gave me cancer
      }
    } // attach
  }; // Drupal.behaviors.home_formatter_mobile_v1

})(jQuery);
